import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

interface TopicInstructionSuggestionPayload {
  name: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class AIAutofillService {
  constructor(private apiService: ApiService) {}

  suggestTopicInstruction(payload: TopicInstructionSuggestionPayload): Promise<string> {
    return this.apiService.postPromise(`/ai-autofill/topic-instructions`, payload);
  }
}
